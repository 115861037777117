body {
  background-color: #f1f1f1;
}

:root {
  --toastify-color-info: #{$info} !important;
  --toastify-color-success: #{$success} !important;
  --toastify-color-warning: #{$warning} !important;
  --toastify-color-error: #{$danger} !important;
}

.container-full-height {
  height: calc(100vh - 126px);
}

.btn-primary,
.btn-primary:hover,
.btn-primary:active,
.btn-outline-primary:hover,
.btn-outline-primary:active {
  color: white !important;
}

.btn-secondary,
.btn-secondary:hover,
.btn-secondary:active,
.btn-outline-secondary:hover,
.btn-outline-secondary:active {
  color: white !important;
}

.btn-info,
.btn-info:hover,
.btn-info:active,
.btn-outline-info:hover,
.btn-outline-info:active {
  color: white !important;
}

.btn-success,
.btn-success:hover,
.btn-success:active,
.btn-outline-success:hover,
.btn-outline-success:active {
  color: white !important;
}

table {
  position: relative;
}

th {
  position: sticky;
  top: 0;
}

.sheep-table {
  max-height: 70vh;
}

.avatar {
  width: 3rem;
  height: 3rem;
}

.avatar-title {
  align-items: center;
  display: flex;
  font-weight: 500;
  height: 100%;
  justify-content: center;
  width: 100%;
}

.search-box {
  position: relative;
}

.search-box .form-control {
  padding-left: 40px;
}

.search-box .search-icon {
  align-items: center;
  color: #878a99;
  display: flex;
  font-size: 14px;
  height: 100%;
  left: 13px;
  position: absolute;
  top: 0;
}

.pe-always {
  cursor: pointer;
}
