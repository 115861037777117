.sidebar {
  border-right: 1px solid #fff;
  bottom: 0;
  box-shadow: 0 2px 4px #0f223a1f;
  margin-top: 0;
  padding: 0 0 95px;
  position: fixed;
  top: 0;
  transition: all .1s ease-out;
  width: 250px;
  z-index: 1002;
  margin-left: -250px;
}

.sidebar.is-open {
  margin-left: 0;
  transition: all .1s ease-out;
}

.sidebar ul p {
  padding: 10px;
}

.menu-open {
  background: $light;
}

.nav-link {
  opacity: 60%;
  color: white !important;
}

.nav-link:hover, .nav-link:focus {
  opacity: 85%;
}

.nav-link.active {
  opacity: 100%;
}

li a.dropdown-toggle::after {
  display: inline-flex;
  position: relative;
  left: 60%;
  top: 10%;
}

.sidebar-header > span {
  position: relative;
  float: right;
  margin: 0.5em;
  font-size: 2rem;
  cursor: pointer;
  display: none;
}

.side-menu {
  overflow-y: scroll;
}

.side-menu::-webkit-scrollbar {
  width: 10px;
}

.side-menu::-webkit-scrollbar-thumb {
  border-radius: 3px;
}

.content {
  padding: 20px;
}

.content.is-open {
  margin-left: 250px;
}

.sidebar-logo {
  filter: brightness(0) invert(1);
}

@media only screen and (max-width: 576px) {
  .sidebar {
    position: fixed;
    top: 0;
    left: 0;
  }

  .content.is-open {
    margin-left: 0;
  }

  .sidebar.is-open {
    width: 100vw;
    margin-left: 0;
    transition: all 0.1s, height 0s;
  }

  .sidebar.is-open > .sidebar-header span {
    display: unset;
  }

  li a.dropdown-toggle::after {
    display: inline-block;
    position: relative;
    left: 68%;
  }
}
